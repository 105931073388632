import Layout from 'components/comachicart/Layout';
import Maintenance from "components/comachicart/Maintenance";
import SEO from "components/seo";
import React, { useEffect, useState } from "react";

import { releace_endpoint_20240627_hotfix } from "../../../constants";

const ReleacePage = () => {
  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(releace_endpoint_20240627_hotfix);

      const jsonData = await response.json();
      setData(jsonData[0]);
      console.log(jsonData[0]);
    } catch (error) {
      console.log("データの取得中にエラーが発生しました:", error);
    }
  };

  return (
    <Layout>
      <SEO title="comachicart" path="comachicart/maintenance/20240627" />
      {data != null && <Maintenance data={data} />}
    </Layout>
  );
};

export default ReleacePage;
